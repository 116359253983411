import hamburguer from "./../../assets/img/hamburguer.svg";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Whatsapp from "./../../components/navbar/logo_whatsapp.svg";
import PhoneIcon from "./../../components/navbar/phone.svg";
import { useTranslation } from "react-i18next";
import PhoneSupportDialog from "./phoneSupporDialog";

export default function SideBar({ whatsapp, geo, lang }) {
  const [state, setState] = useState(false);
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };

  const openDialog = () => {
    setOpen(true);
  };

  return (
    <>
      <div className="navbar-text-right navbar-bg-[#0371e8] ">
        <>
          <button
            type="button"
            onClick={toggleDrawer(true)}
            className="navbar-ml-4 navbar-mt-1"
          >
            <img src={hamburguer} />
          </button>
          <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
            <Box
              className="navbar-bg-[#0371e8] navbar-h-screen "
              sx={{ width: 320 }}
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <div className="navbar-flex navbar-justify-end navbar-pt-6">
                <IconButton onClick={toggleDrawer(false)}>
                  <CloseIcon className="navbar-text-white " fontSize="large" />
                </IconButton>
              </div>
              <div className="navbar-text-right navbar-text-white navbar-text-2xl navbar-font-medium navbar-pt-[122px] navbar-space-y-8 navbar-pr-4 navbar-flex navbar-items-end navbar-justify-center navbar-flex-col">
                <div className="navbar-flex navbar-flex-col navbar-justify-center navbar-space-y-8">
                  <a href={`/${geo?.country.toLowerCase()}/mi-voucher`}>{t("options.myVoucher")}</a>
                  <a href={`/${geo?.country.toLowerCase()}/preguntas-frecuentes`}>{t("options.faqs")}</a>
                </div>
                <div className="navbar-flex navbar-flex-col navbar-justify-center navbar-space-y-6">
                  {geo?.country_id == 164 && lang !== "pt-BR" && (
                    <button
                      className="navbar-flex navbar-justify-end navbar-pt-[112px]"
                      onClick={openDialog}
                    >
                      <img src={PhoneIcon} className="navbar-mr-[10px]" />
                      Teléfono
                    </button>
                  )}
                  <a
                    href={`https://wa.me/${whatsapp}`}
                    target="_blank"
                    className="navbar-flex navbar-justify-end"
                  >
                    <img src={Whatsapp} className="navbar-mr-[10px]" />
                    {t("options.sales")}
                  </a>
                </div>
              </div>
            </Box>
          </Drawer>
        </>
      </div>
      <div>{open && <PhoneSupportDialog dialogVisibility={setOpen} />}</div>
    </>
  );
}
