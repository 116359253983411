import { useTranslation } from "react-i18next";
import logo_whatsapp from "./logo_whatsapp.svg";

const NavbarMenu = ({ whatsapp, isLanding }) => {
  const { t } = useTranslation();

  let url = window.location.href;
  const geo = url.split("/")[3] || "ar";

  return (
    <div
      className="navbar-navbar-menu 
      navbar-text-white navbar-font-titillium navbar-text-base xl:navbar-text-xl
      navbar-font-semibold navbar-space-x-[20px] xl:navbar-space-x-[43px] navbar-flex
        xl:navbar-ml-[202px]
        md:navbar-pl-[34px]
        md:navbar-pr-[55px]
        "
    >
      {!isLanding && (
        <a href={`/${geo}/mi-voucher`}>{t("options.myVoucher")}</a>
      )}
      <a href={`/${geo}/preguntas-frecuentes`}>{t("options.faqs")}</a>
      <a
        className="navbar-flex"
        href={`https://wa.me/${whatsapp}`}
        target="_blank"
      >
        <img className="navbar-mr-2" src={logo_whatsapp} />
        <span>{t("options.sales")}</span>
      </a>
    </div>
  );
};

export default NavbarMenu;
